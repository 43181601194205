define("discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/invitee", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "truth-helpers", "discourse/components/avatar-flair", "discourse/helpers/avatar", "discourse/helpers/concat-class", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _truthHelpers, _avatarFlair, _avatar, _concatClass, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoursePostEventInvitee extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get statusIcon() {
      switch (this.args.invitee.status) {
        case "going":
          return "fa-check";
        case "interested":
          return "fa-star";
        case "not_going":
          return "fa-times";
      }
    }
    get flairName() {
      const string = `discourse_post_event.models.invitee.status.${this.args.invitee.status}`;
      return (0, _i18n.default)(string);
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <li
          class={{concatClass
            "event-invitee"
            (if @invitee.status (concat "status-" @invitee.status))
            (if (eq this.currentUser.id @invitee.user.id) "is-current-user")
          }}
        >
          <a class="topic-invitee-avatar" data-user-card={{@invitee.user.username}}>
            {{avatar
              @invitee.user
              imageSize=(if this.site.mobileView "tiny" "large")
            }}
            {{#if this.statusIcon}}
              <AvatarFlair
                @flairName={{this.flairName}}
                @flairUrl={{this.statusIcon}}
              />
            {{/if}}
          </a>
        </li>
      
    */
    {
      "id": "XZIgZYiv",
      "block": "[[[1,\"\\n    \"],[10,\"li\"],[15,0,[28,[32,0],[\"event-invitee\",[52,[30,1,[\"status\"]],[28,[32,1],[\"status-\",[30,1,[\"status\"]]],null]],[52,[28,[32,2],[[30,0,[\"currentUser\",\"id\"]],[30,1,[\"user\",\"id\"]]],null],\"is-current-user\"]],null]],[12],[1,\"\\n      \"],[10,3],[14,0,\"topic-invitee-avatar\"],[15,\"data-user-card\",[30,1,[\"user\",\"username\"]]],[12],[1,\"\\n        \"],[1,[28,[32,3],[[30,1,[\"user\"]]],[[\"imageSize\"],[[52,[30,0,[\"site\",\"mobileView\"]],\"tiny\",\"large\"]]]]],[1,\"\\n\"],[41,[30,0,[\"statusIcon\"]],[[[1,\"          \"],[8,[32,4],null,[[\"@flairName\",\"@flairUrl\"],[[30,0,[\"flairName\"]],[30,0,[\"statusIcon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@invitee\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/invitee.js",
      "scope": () => [_concatClass.default, _helper.concat, _truthHelpers.eq, _avatar.default, _avatarFlair.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DiscoursePostEventInvitee;
});