define("discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-invitees", ["exports", "@glimmer/tracking", "@ember-compat/tracked-built-ins", "discourse/models/user", "discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-invitee"], function (_exports, _tracking, _trackedBuiltIns, _user, _discoursePostEventInvitee) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoursePostEventInvitees {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new DiscoursePostEventInvitees(args);
    }
    static #_ = (() => dt7948.g(this.prototype, "_invitees", [_tracking.tracked]))();
    #_invitees = (() => (dt7948.i(this, "_invitees"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "_suggestedUsers", [_tracking.tracked]))();
    #_suggestedUsers = (() => (dt7948.i(this, "_suggestedUsers"), void 0))();
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.invitees = args.invitees || [];
      this.suggestedUsers = args.meta?.suggested_users || [];
    }
    get invitees() {
      return this._invitees;
    }
    set invitees(invitees) {
      if (invitees === void 0) {
        invitees = [];
      }
      this._invitees = new _trackedBuiltIns.TrackedArray(invitees.map(i => _discoursePostEventInvitee.default.create(i)));
    }
    get suggestedUsers() {
      return this._suggestedUsers;
    }
    set suggestedUsers(suggestedUsers) {
      if (suggestedUsers === void 0) {
        suggestedUsers = [];
      }
      this._suggestedUsers = new _trackedBuiltIns.TrackedArray(suggestedUsers.map(su => _user.default.create(su)));
    }
    add(invitee) {
      this.invitees.push(invitee);
      this.suggestedUsers = this.suggestedUsers.filter(su => su.id !== invitee.user.id);
    }
    remove(invitee) {
      this.invitees = this.invitees.filter(i => i.user.id !== invitee.user.id);
    }
  }
  _exports.default = DiscoursePostEventInvitees;
});