define("discourse/plugins/discourse-calendar/discourse/helpers/format-future-date", ["exports", "@ember/template", "discourse/plugins/discourse-calendar/discourse/lib/guess-best-date-format"], function (_exports, _template, _guessBestDateFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(date) {
    date = moment.utc(date).tz(moment.tz.guess());
    const format = (0, _guessBestDateFormat.default)(date);
    return (0, _template.htmlSafe)(date.format(format));
  }
});