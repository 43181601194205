define("discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-event-stats", ["exports", "@glimmer/tracking"], function (_exports, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoursePostEventEventStats {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new DiscoursePostEventEventStats(args);
    }
    static #_ = (() => dt7948.g(this.prototype, "going", [_tracking.tracked], function () {
      return 0;
    }))();
    #going = (() => (dt7948.i(this, "going"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "interested", [_tracking.tracked], function () {
      return 0;
    }))();
    #interested = (() => (dt7948.i(this, "interested"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "invited", [_tracking.tracked], function () {
      return 0;
    }))();
    #invited = (() => (dt7948.i(this, "invited"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "notGoing", [_tracking.tracked], function () {
      return 0;
    }))();
    #notGoing = (() => (dt7948.i(this, "notGoing"), void 0))();
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.going = args.going;
      this.invited = args.invited;
      this.interested = args.interested;
      this.notGoing = args.not_going;
    }
  }
  _exports.default = DiscoursePostEventEventStats;
});