define("discourse/plugins/discourse-calendar/discourse/connectors/topic-list-after-title/event-badge", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-calendar/discourse/components/event-date", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _eventDate, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventBadge extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{~#if this.siteSettings.discourse_post_event_enabled~}}
          {{~#if @outletArgs.topic.event_starts_at~}}
            <EventDate @topic={{@outletArgs.topic}} />
          {{~/if~}}
        {{~/if~}}
      
    */
    {
      "id": "HUnvx4VE",
      "block": "[[[41,[30,0,[\"siteSettings\",\"discourse_post_event_enabled\"]],[[[41,[30,1,[\"topic\",\"event_starts_at\"]],[[[8,[32,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null]],[]],null]],[]],null]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/connectors/topic-list-after-title/event-badge.js",
      "scope": () => [_eventDate.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EventBadge;
});