define("discourse/plugins/discourse-calendar/discourse/initializers/discourse-post-event-decorator", ["exports", "discourse/lib/plugin-api", "I18n", "discourse/plugins/discourse-calendar/discourse/components/discourse-post-event", "discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-event", "discourse/plugins/discourse-calendar/discourse/lib/guess-best-date-format", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _pluginApi, _I18n, _discoursePostEvent, _discoursePostEventEvent, _guessBestDateFormat, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _validEventPreview(eventContainer) {
    eventContainer.innerHTML = "";
    eventContainer.classList.add("discourse-post-event-preview");
    const statusLocaleKey = `discourse_post_event.models.event.status.${eventContainer.dataset.status || "public"}.title`;
    if (_I18n.default.lookup(statusLocaleKey, {
      locale: "en"
    })) {
      const statusContainer = document.createElement("div");
      statusContainer.classList.add("event-preview-status");
      statusContainer.innerText = _I18n.default.t(statusLocaleKey);
      eventContainer.appendChild(statusContainer);
    }
    const datesContainer = document.createElement("div");
    datesContainer.classList.add("event-preview-dates");
    const startsAt = moment.tz(eventContainer.dataset.start, eventContainer.dataset.timezone || "UTC");
    const endsAt = eventContainer.dataset.end && moment.tz(eventContainer.dataset.end, eventContainer.dataset.timezone || "UTC");
    const format = (0, _guessBestDateFormat.default)(startsAt, endsAt);
    let datesString = `<span class='start'>${startsAt.tz(moment.tz.guess()).format(format)}</span>`;
    if (endsAt) {
      datesString += ` → <span class='end'>${endsAt.tz(moment.tz.guess()).format(format)}</span>`;
    }
    datesContainer.innerHTML = datesString;
    eventContainer.appendChild(datesContainer);
  }
  function _invalidEventPreview(eventContainer) {
    eventContainer.classList.add("discourse-post-event-preview", "alert", "alert-error");
    eventContainer.classList.remove("discourse-post-event");
    eventContainer.innerText = _I18n.default.t("discourse_post_event.preview.more_than_one_event");
  }
  function _decorateEventPreview(api, cooked) {
    const eventContainers = cooked.querySelectorAll(".discourse-post-event");
    eventContainers.forEach((eventContainer, index) => {
      if (index > 0) {
        _invalidEventPreview(eventContainer);
      } else {
        _validEventPreview(eventContainer);
      }
    });
  }
  function initializeDiscoursePostEventDecorator(api) {
    api.decorateCookedElement((cooked, helper) => {
      if (cooked.classList.contains("d-editor-preview")) {
        _decorateEventPreview(api, cooked);
        return;
      }
      if (helper) {
        const post = helper.getModel();
        if (!post?.event) {
          return;
        }
        const postEventNode = cooked.querySelector(".discourse-post-event");
        if (!postEventNode) {
          return;
        }
        const wrapper = document.createElement("div");
        postEventNode.before(wrapper);
        const event = _discoursePostEventEvent.default.create(post.event);
        helper.renderGlimmer(wrapper, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                  <DiscoursePostEvent @event={{event}} />
                
        */
        {
          "id": "wx3uhHbS",
          "block": "[[[1,\"\\n          \"],[8,[32,0],null,[[\"@event\"],[[32,1]]],null],[1,\"\\n        \"]],[],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/initializers/discourse-post-event-decorator.js",
          "scope": () => [_discoursePostEvent.default, event],
          "isStrictMode": true
        }), (0, _templateOnly.default)(undefined, undefined)));
      }
    }, {
      id: "discourse-post-event-decorator"
    });
    api.replaceIcon("notification.discourse_post_event.notifications.invite_user_notification", "calendar-day");
    api.replaceIcon("notification.discourse_post_event.notifications.invite_user_auto_notification", "calendar-day");
    api.replaceIcon("notification.discourse_calendar.invite_user_notification", "calendar-day");
    api.replaceIcon("notification.discourse_post_event.notifications.invite_user_predefined_attendance_notification", "calendar-day");
    api.replaceIcon("notification.discourse_post_event.notifications.before_event_reminder", "calendar-day");
    api.replaceIcon("notification.discourse_post_event.notifications.after_event_reminder", "calendar-day");
    api.replaceIcon("notification.discourse_post_event.notifications.ongoing_event_reminder", "calendar-day");
  }
  var _default = _exports.default = {
    name: "discourse-post-event-decorator",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_post_event_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.7", initializeDiscoursePostEventDecorator);
      }
    }
  };
});