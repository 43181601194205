define("discourse/plugins/discourse-calendar/discourse/services/discourse-post-event-api", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-event", "discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-invitee", "discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-invitees"], function (_exports, _service, _ajax, _discoursePostEventEvent, _discoursePostEventInvitee, _discoursePostEventInvitees) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Discoure post event API service. Provides methods to interact with the discourse post event API.
   *
   * @module DiscoursePostEventApi
   * @implements {@ember/service}
   */
  class DiscoursePostEventApi extends _service.default {
    async event(id) {
      const result = await this.#getRequest(`/events/${id}`);
      return _discoursePostEventEvent.default.create(result.event);
    }
    async events() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const result = await this.#getRequest("/events", data);
      return result.events.map(e => _discoursePostEventEvent.default.create(e));
    }
    async listEventInvitees(event) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const result = await this.#getRequest(`/events/${event.id}/invitees`, data);
      return _discoursePostEventInvitees.default.create(result);
    }
    async updateEvent(event) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const updatedEvent = await this.#putRequest(`/events/${event.id}`, {
        event: data
      });
      event.updateFromEvent(updatedEvent);
      return event;
    }
    async updateEventAttendance(event) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      if (!event.watchingInvitee) {
        return;
      }
      const result = await this.#putRequest(`/events/${event.id}/invitees/${event.watchingInvitee.id}`, {
        invitee: data
      });
      event.watchingInvitee = _discoursePostEventInvitee.default.create(result.invitee);
      event.sampleInvitees.forEach(invitee => {
        if (invitee.id === event.watchingInvitee.id) {
          invitee.status = event.watchingInvitee.status;
        }
      });
      event.stats = result.invitee.meta.event_stats;
      event.shouldDisplayInvitees = result.invitee.meta.event_should_display_invitees;
      return event;
    }
    async leaveEvent(event, invitee) {
      await this.#deleteRequest(`/events/${event.id}/invitees/${invitee.id}`);
      event.sampleInvitees = event.sampleInvitees.filter(i => i.id !== invitee.id);
      if (event.watchingInvitee?.id === invitee.id) {
        event.watchingInvitee = null;
      }
    }
    async joinEvent(event) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const result = await this.#postRequest(`/events/${event.id}/invitees`, {
        invitee: data
      });
      const invitee = _discoursePostEventInvitee.default.create(result.invitee);
      if (!data.user_id) {
        event.watchingInvitee = invitee;
        event.sampleInvitees.push(event.watchingInvitee);
      }
      event.stats = result.invitee.meta.event_stats;
      event.shouldDisplayInvitees = result.invitee.meta.event_should_display_invitees;
      return invitee;
    }
    get #basePath() {
      return "/discourse-post-event";
    }
    #getRequest(endpoint) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return (0, _ajax.ajax)(`${this.#basePath}${endpoint}`, {
        type: "GET",
        data
      });
    }
    #putRequest(endpoint) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return (0, _ajax.ajax)(`${this.#basePath}${endpoint}`, {
        type: "PUT",
        data
      });
    }
    #postRequest(endpoint) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return (0, _ajax.ajax)(`${this.#basePath}${endpoint}`, {
        type: "POST",
        data
      });
    }
    #deleteRequest(endpoint) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return (0, _ajax.ajax)(`${this.#basePath}${endpoint}`, {
        type: "DELETE",
        data
      });
    }
  }
  _exports.default = DiscoursePostEventApi;
});