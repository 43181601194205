define("discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-invitee", ["exports", "@glimmer/tracking", "discourse/models/user"], function (_exports, _tracking, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoursePostEventInvitee {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new DiscoursePostEventInvitee(args);
    }
    static #_ = (() => dt7948.g(this.prototype, "status", [_tracking.tracked]))();
    #status = (() => (dt7948.i(this, "status"), void 0))();
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.id = args.id;
      this.post_id = args.post_id;
      this.status = args.status;
      this.user = this.#initUserModel(args.user);
    }
    #initUserModel(user) {
      if (!user || user instanceof _user.default) {
        return user;
      }
      return _user.default.create(user);
    }
  }
  _exports.default = DiscoursePostEventInvitee;
});