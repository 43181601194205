define("discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/creator", ["exports", "@glimmer/component", "discourse/helpers/avatar", "discourse/lib/utilities", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _avatar, _utilities, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoursePostEventCreator extends _component.default {
    get username() {
      return this.args.user.name || (0, _utilities.formatUsername)(this.args.user.username);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class="creators">
          <span class="created-by">{{i18n "discourse_post_event.created_by"}}</span>
    
          <span class="event-creator">
            <a class="topic-invitee-avatar" data-user-card={{@user.username}}>
              {{avatar @user imageSize="tiny"}}
              <span class="username">{{this.username}}</span>
            </a>
          </span>
        </span>
      
    */
    {
      "id": "xp4ZBM+r",
      "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"creators\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"created-by\"],[12],[1,[28,[32,0],[\"discourse_post_event.created_by\"],null]],[13],[1,\"\\n\\n      \"],[10,1],[14,0,\"event-creator\"],[12],[1,\"\\n        \"],[10,3],[14,0,\"topic-invitee-avatar\"],[15,\"data-user-card\",[30,1,[\"username\"]]],[12],[1,\"\\n          \"],[1,[28,[32,1],[[30,1]],[[\"imageSize\"],[\"tiny\"]]]],[1,\"\\n          \"],[10,1],[14,0,\"username\"],[12],[1,[30,0,[\"username\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@user\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/creator.js",
      "scope": () => [_i18n.default, _avatar.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DiscoursePostEventCreator;
});