define("discourse/plugins/discourse-calendar/discourse/lib/full-calendar-default-options", ["exports", "pretty-text/sanitizer", "discourse/plugins/discourse-calendar/discourse/lib/calendar-locale", "discourse/plugins/discourse-calendar/discourse/lib/popover"], function (_exports, _sanitizer, _calendarLocale, _popover) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fullCalendarDefaultOptions;
  function fullCalendarDefaultOptions() {
    return {
      eventClick: function () {
        (0, _popover.destroyPopover)();
      },
      locale: (0, _calendarLocale.getCurrentBcp47Locale)(),
      buttonText: (0, _calendarLocale.getCalendarButtonsText)(),
      eventMouseEnter: function (_ref) {
        let {
          event,
          jsEvent
        } = _ref;
        (0, _popover.destroyPopover)();
        const htmlContent = (0, _sanitizer.escape)(event.title);
        (0, _popover.buildPopover)(jsEvent, htmlContent);
      },
      eventMouseLeave: function () {
        (0, _popover.destroyPopover)();
      }
    };
  }
});